// topbar menu styles start
.topBarImage {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0px;
    margin-bottom: 10px
}

.topBarImageAlignment {
    text-align: center;
}
body{
    overflow: hidden;
}
body a:hover {
    color: $color-template;
}

.theme-light .form input,
.theme-light .form textarea {
    color: black;
}

.themeColorText {
    color: #f17038;
}

.paginationcss {
    margin-right: 0;
}

.topbarMenu {
    width: 200px;
    border-radius: 0;
    border: none;
    padding-bottom: 15px 0;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    margin-top: 0;
}

.theme-white {
    // border: 1px solid #ddd !important;
    background: white !important
}

.iconstyle {
    background: #d6d7da !important;
    border: 1px solid #d6d7da !important;
}


// topbar menu styles end
.form input {
    background: white;
    margin-bottom: 0.5rem;
}

.p-column-filter-overlay-menu .p-column-filter-operator {
    display: none;
}

.p-column-filter-overlay-menu .p-column-filter-add-rule {
    display: none;
}

.p-column-filter-buttonbar .p-button:not(.p-button-icon-only) {
    width: 100%
}

.p-column-filter-menu-button,
.p-column-filter-clear-button {
    color: white
}

body .p-datatable .p-datatable-tbody>tr.p-highlightHoliday {
    background-color: #fbff2b;
}

body .p-datatable .p-datatable-tbody>tr.p-highlightWeekend {
    background-color: #1efd00;
}

body .p-datatable .p-datatable-tbody>tr.p-highlightLessThan6Hrs {
    background-color: #ff0000;
}

body .p-datatable .p-sortable-column .p-sortable-column-icon {
    color: $color-white;
}

.p-datatable.p-datatable-sm .p-datatable-tbody>tr>td {
    font-size: 15px;
}

.p-column-filter-menu-button.p-column-filter-menu-button-active {
    background: $button-text-color;
    color: $button-bg-color;
}

body .p-fluid .p-autocomplete .p-autocomplete-dropdown.p-button {
    width: 2em;
    height: 32px;
}

body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token input {
    font-size: 12px;
}

body .p-button {
    background-color: $button-bg-color;
    border-color: $button-bg-color;
    color: $button-text-color;
    padding: 8px;

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
        background-color: $button-bg-color;
        border-color: $button-hover-color !important;
        color: $button-text-color !important;
    }
}

.p-button:enabled:hover,
.p-button:enabled:active {
    background-color: $button-hover-color;
    border-color: $button-hover-color ;
    color: $button-text-color;
}

.p-button.p-button-outlined {
    color: $button-bg-color;
}

.p-button.button-filter {
    padding: 8px 14px;
    font-size: small;
    height: 42px;
}

.p-button-sm.button-filter {
    padding: 15px;
}

.p-column-filter-menu-button.p-column-filter-menu-button-open {
    background: $button-bg-color;
    border-color: $button-bg-color;
    color: $button-text-color;
}

//autocomplete multi-select
.p-autocomplete-input-token {
    height: 25px;
}

.p-autocomplete-token.p-highlight {
    margin-bottom: 2px;
}

// Dasboard 
.dashBoardCardWithScroll {
    height: 390px;
}

.p-column-title {
    text-transform: capitalize !important;
    font-size: 14px !important;
}

//Scrollable table with Sticky Header
.table-fixed tbody {
    height: 200px;
    overflow-y: auto;
    width: 100%;
}

.table-fixed thead,
.table-fixed tbody,
.table-fixed tr,
.table-fixed td,
.table-fixed th {
    display: block;
}

.table-fixed tr:after {
    content: "";
    display: block;
    visibility: hidden;
    clear: both;
}

.table-fixed tbody td,
.table-fixed thead>tr>th {
    float: left;
}

//kpiTable
.kpiTable {
    background-color: $color-template;
    color: black;
}

.colorLegend {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

body .p-multiselect-panel .p-multiselect-items {
    color: black
}

// body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight{
//     background-color:$color-template;

// }
body .p-checkbox .p-checkbox-box.p-highlight {
    background-color: $color-template;
    border-color: $color-template
}

body .p-radiobutton .p-radiobutton-box.p-highlight {
    background-color: $color-template !important;
    border-color: $color-template !important;
}

body .p-input-overlay-visible {
    z-index: 9999 !important;
}

.color-picker__popover {
    z-index: 9999;
}

.calendar .rbc-off-range-bg {
    background-color: #e5e5e5;
}

.textElipses {
    font-weight: 500;
    color: $color-template;
    cursor: pointer;
}

.p-dropdown{
    height: 30px;
    justify-content: center;
    align-items: center;
}
.p-dropdown-trigger {
    height: 100%;
}
// .css-vj8t7z{
//     min-height: 0 !important
// }
// .css-1hwfws3{
//     position: static !important;
// }
// .css-10nd86i react-select{
//     height: 33px
// }
// .css-2o5izw .react-select__control .react-select__control--is-focused{
//     height: 33px;
// }

.botmessagesText {
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.1);
    padding-top: 8px;
    padding-left: 7px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-left: 5px;
    align-self: flex-start;
    width: 90%;
    border-color: red
}

.usermessagesText {
    background-color: #f0f1ef;
    padding-top: 5px;
    padding-left: 7px;
    margin-right: 5px;
    margin-top: 7px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    width: 90%;
    text-align: left
}

.p-multiselect-label-container {
    height: 30px;
}

.p-autocomplete-input-token input {
    border: none
}

.logout_modal_width {
    max-width: 400px;
}

.call_action_column {
    text-align: 'center';
    text-decoration: underline;
    color: $color-template;
    font-weight: bold;
    cursor: pointer;
}

.p-datatable-row {
    cursor: initial !important;
}

.p-datatable-row:hover {
    background: transparent
}

.settings_form {
    width: 23%;
    padding: 5px 10px;
    font-size: 12px;
    height: 32px;
    transition: border 0.3s;
    background: transparent;
    border: solid 1px lightgray;
}

.form .settings_form:focus,
.form .settings_form:active {
    outline: none;
    border-color: #ffcd08;
}

.pending_badge,
.started_badge {
    padding: 5px;
    font-size: 14px;
    margin-bottom: 2px;
    padding-left: 7px;
    padding-right: 7px;
    color: white;
}

.vertical_middle {
    justify-content: center;
    align-items: center;
    display: flex;
}

body .p-component {
    font-family: unset;
}

.pagenumber-indicator {
    display: flex;
    height: 100%;
    align-items: center;
}

.rdw-editor-toolbar {
    img {
        height: 20px;
        width: 20px;
    }
}

.p-column-header-content:has(.p-checkbox) {
    justify-content: center;
}

.p-filter-column {
    text-align: center !important;
    padding: 0.40rem !important;

    .p-inputtext {
        padding: 0.45rem 0.75rem;
    }

    .p-column-filter-clear-button {
        display: none;
    }

    .p-multiselect-label {
        padding: 0.25rem 0.75rem;
        color: black;
    }

}

.p-autocomplete input {
    width: 100%;
}

.p-button {
    &:hover {
        background: $color-template !important;
        border-color: $color-template
    }
}

.ck-content {
    min-height: 30vh;
}

.p-button.p-button-icon-only {
    height: 3.2rem;
}

.p-autocomplete-multiple-container {
    width: 100% !important;
}

.p-resizable-column {
    padding: 5px !important;
    font-weight: 600 !important;
    font-size: 15px !important;
}

//file upload css
.p-fileupload-file-badge {
    display: none !important;
}

.p-fileupload .p-fileupload-content {
    padding: 0px !important;
}

//css for buttons in the login component 
.scale {
    transform: scale(0.80);

    button {
        white-space: nowrap;
    }
}

.text-info {
    color: rgb(47, 39, 131) !important;
}

.login-container .login4 .card .card-body img {
    width: 250px;
    height: 250px;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
    color: #fff;
    height: 1rem;
    width: 1rem;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
    color: #fff;
    border-color: transparent;
    background: transparent;
}

.p-dialog-header {
    background-color: $button-bg-color !important;
    color: $button-text-color !important;
    height: 4rem !important;
    text-align: left;
    padding: 0.5rem;
}

// .p-datatable .p-column-header-content{
//     justify-content: center !important;
// }

.mini-square-modal {
    max-width: 650px;
    width: 100%;
    padding: 20px;
    border-radius: 8px;
}

.form-group {
    margin-bottom: 15px;
}

.modal-buttons button {
    min-width: 80px;
}

.bold {
    font-weight: bold;
}

.addpeople {
    font: var(--dt-title-medium-font, 500 1rem/1.5rem "Google Sans"),
        "Google Sans",
        Roboto,
        Arial,
        sans-serif;
}

.addpeople {
    display: block;
}



/* ShareModal.css */

.user-access {
    position: relative;
    margin-bottom: 10px;
    margin-top: 10px;
}

.user-circle {
    width: 30px;
    height: 30px;
    border-radius: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin-right: 10px;
}

.user-initial {
    font-size: 16px;
    font-weight: bold;
}

.user-email {
    font-size: 14px;
    margin-right: 10px;
}

.user-permissions {
    font-size: 14px;
    margin-left: auto;
    color: darkgray;
    /* Pushes permissions to the end of the flex container */
}

.user-permissions .p-dropdown {
    border: none;
    height: 10px;
}

.dropdown-options {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    padding: 5px 10px;
    border-radius: 4px;
    margin-top: 10px;
    z-index: 1;
}

.dropdown-option {
    padding: 5px 0;
    cursor: pointer;
}

/* General styling adjustments */
.modal-body {
    padding: 20px;
    max-height: 70vh;
    overflow-y: auto;
}

.list-group-item {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    padding: 10px 0;
    border: none;
    border-radius: 0;
}

.list-group-item:hover {
    background-color: #f0f0f0;
}

.bold {
    font-weight: bold;
    font-size: 1.5rem;
}

.modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin-bottom: 20px;
}

.folder-icon {
    margin-right: 10px;
}

.move-folder-item {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.folder-name {
    display: flex;
    align-items: center;
}

.move-button {
    opacity: 0;
    transition: opacity 0.2s;
    margin-top: 1rem;
}

.move-folder-item:hover .move-button {
    opacity: 1;
}

.list-group-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.list-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.move-folder-item,
.move-file-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
}

.folder-name {
    display: flex;
    align-items: center;
    gap: 10px;
}

.folder-icon,
.file-icon {
    margin-right: 10px;
}

.move-button {
    display: none;
    position: absolute;
    right: 10px;
}

.move-folder-item:hover .move-button {
    display: inline-block;
}

.disabled-file {
    color: gray;
    opacity: 0.6;
}

.no-data {
    font-size: 14px;
    font-weight: bold;
}

.align_move {
    color: black;
    margin-right: 9px;
    width: 15px;
    cursor: pointer;
}

.align_table_moveIcon {
    color: black;
    margin-left: 9px;
    width: 15px;
    cursor: pointer;
}

.align_delete {
    color: #bf1725;
    width: 13;
    margin-left: 10px;
    cursor: pointer;
}

.custom-file-button {
    background-color: #12225f;
    color: white;
    padding: 12px 17px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
}

.custom-file-button:hover {
    background-color:#12225f ;
}

.modal-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.buttonprimary {
    color: white;
    background-color: #3c6177;;
    border-color: #3c6177;;
}

.buttonprimary:hover {
    background-color: #3c6177;
    ;
    border-color: #3c6177;
    ;
}
.folders-container,
.files-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.folders-container h4,
.files-container h4 {
    width: 100%;
}

.dropzone {
    border: 2px dashed white;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload-icon-container {
    position: absolute;
    bottom: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.text-icon-container {
    position: absolute;
    bottom: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.animate-cloud {
    animation: bounce 1s infinite;
}
.overflow-x-hidden{
    overflow-x: hidden !important;
}

@keyframes bounce {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }
}

.dropLocationStyle {
    margin-top: 20px;
    background-color: rgb(13, 110, 253);
    border-radius: 20px;
    padding: 10px 20px;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.progress-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    background-color: #f3f3f3;
    border-radius: 5px;
    overflow: hidden;
}

.progress-bar {
    height: 20px;
    background-color: #4caf50;
    text-align: center;
    line-height: 20px;
    color: white;
    transition: width 0.4s ease;
}

.loader-close-button,
.loader-toggle-button {
    background: none;
    border: none;
    color: black;
    font-size: 20px;
    cursor: pointer;
    padding: 0;
}
.loader-container {
    width: 80%;
    max-width: 500px;
    z-index: 9999;
    background: white;
    padding: 10px;
    border-radius: 5px;
    overflow-y: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -webkit-transform: translate3d(0, 24px, 0);
    transform: translate3d(0, 24px, 0);
    bottom: 20px;
    left: auto;
    max-height: 223px;
    position: absolute;
    right: 9px;
}
.loader-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding: 10px;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.loader-status {
    margin-top: 40px;
    /* Adjust margin to accommodate fixed header */
}

.uploaded-files-list {
    margin-top: 10px;
    text-align: left;
}

.uploaded-file-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
    padding: 5px;
    padding-top: 15px;
    border-bottom: 1px solid #ddd;
}

.file-info {
    display: flex;
    align-items: center;
}

.file-name {
    margin-left: 10px;
    /* Add spacing between icon and file name */
}

.circular-progress-container {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
}
.check-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    color: green;
}
.circular-progress-wrapper {
    position: relative;
    width: 20px;
    height: 20px;
}
// .p-dialog-header{
//     background-color:#fff !important;
// }

.align_Move_Modal {
    max-width: 40%;
    width: auto;
}
.align_Move_Modal_body{
    padding: 20px;
    max-height: 70vh;
    overflow: auto;
}
.align_Move_Modal_header{
    display: flex;
    align-items: center;
    margin-left: -1rem;
}
.handle_back{
    cursor: pointer;
    font-size: 1.2rem;
}
.move_button{
    margin-left: 10px;
}
.current_location{
    display: flex;
    justify-content: flex-start;
    padding: 10px;
}
.cancel_button{
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
.getcursor{
    cursor: pointer;
}
.default-cursor {
    cursor: default !important;
}
.loader_style{
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 28px;
}
.circular_progress{
    position: relative;
    width: 30px;
    height: 30px;
}

.dropzone-wrapper{
    position: relative;
}

.water-mark::before {
    content: "Drop Items here";
    position: fixed;
    bottom: 15px;
    left: 50%;
    opacity: 0.2;
    font-size: 2rem;
    color: grey;
    white-space: nowrap;
    overflow: hidden !important;
}
.content {
    position: relative;
    z-index: 1;
    padding: 10px;
}

.custom-fileupload {
    height: 2rem; /* Adjusted height */
    font-size: 0.875rem; /* Smaller font size for the button text */
    border-radius: 0.3rem;

    span:first-child{
        font-weight: 800 !important;
        margin: 0 !important;
        // background-color: #007bff;
        color: #fff;         
        border-radius: 5px;   
        font-size: 14px;      
        padding-left: 0.25rem;        
    }
}
.topbar{background: -webkit-linear-gradient(right, #ffffff calc(100% - 212px), #12225f 212px) !important;}
.topbarLogo{color:#ffffff}


// @media screen and (max-width: 576px) {
//     .topbar{background: -webkit-linear-gradient(right, #ffffff calc(100% - 55px), #3c6177 55px) !important;}
// }

.layout--collapse .topbar{background: -webkit-linear-gradient(right, #ffffff calc(100% - 55px), #12225f 55px) !important;}
.whbgrcolor{background-color: #ffffff;}

.folder-box {
    border: 1px dashed rgba(106, 113, 133, 0.3);
    border-radius: 5px;
    padding: 15px;
    background-color: #fff;
    display: inline-block;
    margin: 2px 0;
}
.text-align-left {
    text-align: left;
}
.iconsize{
    font-size: 4em;
    color:#d777489c !important ;
}
.font-size-16{
    font-size: 16px;
}
.subFields{
    color:#999999;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
}
.subsubFields{
    color:#999999;font-size: 12px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
}
body{
    font-family: "Outfit", sans-serif;
    font-optical-sizing: auto;
    opacity: 0.9;
}
.align_move{
    color: #a6b7c1;
}

.menu-bar ul li.active-chat{
    padding: 8px 20px;
    font-weight: 400;
    background-color: #7A70BA !important;
    border-color: #7A70BA !important;
    
}
.menu-bar ul li{
    background-color: rgba(122, 112, 186, 0.1) !important;
    border-color: rgba(122, 112, 186, 0.1) !important;
    padding: 8px 20px;
    font-weight: 400;margin-bottom: 10px;}



.menu-bar ul li.active-chat .chat-name{
    color: #fff !important;
}
.sidebar {
    background: #12225f;
}

.sidebar__link-title {
    font-size: 0.875rem;
    font-weight: 500;
}

.sidebar__link p {
    letter-spacing: 0.7px;
    text-transform: capitalize;
    color: #fff;
    padding-left: 10px !important;
}

.summary_Search_menubar{
    font-family: Outfit, sans-serif, sans-serif!important    ;
    .a{
        background-color: #7a70ba1a !important;
        border-color: rgba(122, 112, 186, 0.1) !important;
        border-style: solid !important;
        height:38px!important;
        
    }
    .menu-bar ul li{
       margin-bottom: 8px!important
    }
.chat-menubar{
   // width: 100%!important;
}

   
}
.chatbot-messages .message .user{
    background-color: #7a70ba1a !important;
    border-color: rgba(122, 112, 186, 0.1) !important;
}

.message {
    &.user {
        background-color: rgba(122, 112, 186, 0.1) !important;
        border-color:rgba(122, 112, 186, 0.1) !important;
    }
}
.chatbot-input button{
    background-color: #7a70ba !important;
    border-color:#7a70ba !important;
    &:disabled {
        background-color: rgba(#7a70ba,0.5) !important;
    }
}

.card-actions {
    position: absolute;
    padding-top: 10px;
    bottom: 10px;
}

.textElipsess {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.share-button {
    padding: 0.8rem !important;
    height: 45px !important;
    width: 45px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mr-3px {
    margin-right: 3px;
}

.dropzone-scroll {
    max-height: 70vh;
    overflow-y: scroll;
    overflow-x:hidden ;
}

.summary-container {
    height: inherit !important;
}

.summary-file-name {
    color: #7a70ba !important;
    font-size: 24px;
    font-weight: 500;
    text-decoration: underline;
}
.summary-message {
    max-width: 100% !important;
    padding: 1rem !important;
}
.summary-loader {
    color: #7a70ba !important;
}

.active_summary_file {
    background-color: rgba( #7a70ba, 0.1);
    .tableCardBody{  
        background-color: inherit !important;
    }
}

.default-icon-color {
    color: #12225f ;
}
.width-15 {
    width: 15px;
}

.margin-right-7 {
    margin-right: 7px
}

.margin-right-9 {
    margin-right: 9px
}

.overflow-hidden {
    overflow: hidden;
}

.file-chat-box {
    .chatbot-container{
        height: 52vh;
    }
    .chatbot-input{
        bottom: 8px;
    }
    .message.user {
        max-width: 70%;
    }
}

.options-icon {
    position: absolute;
    top: 100%;
    right: 0px;
}

.options-menu {
    width: fit-content;
    min-width: unset !important;
    right: 15%;
    max-height: 8rem;
    overflow-y: scroll;
}

.right-70-percent {
    right:  70% !important;
}

.open-down {
    top: 100%; /* Opens below the button */
}
.open-up {
    bottom: 100%; /* Opens above the button */
}

.p-datatable-resizable-table>.p-datatable-tfoot>tr>td .action-options, .p-datatable-resizable-table>.p-datatable-tbody>tr>td .action-options {
    overflow: visible !important;
}

.menu-top {
    bottom:  100% ;
}
.menu-bottom {
    top : 100%
}

.p-datatable>.p-datatable-wrapper {
   min-height: 18rem !important;
}

.menu-bar {
    .chat-options-menu {
        width: fit-content;
        min-width: unset !important;
        max-height: 8rem;
        left: 520px;
        overflow-y: scroll;
        li {
            padding: 5px 20px !important;
            margin: 0px !important;
            background-color: unset !important;
        }
    }
}

.chat-top {
    bottom: 5%;
}


.p-column-filter-menu {
    .pi-filter-icon {
        visibility: hidden;
        opacity: 0;
    }
}
.p-sortable-column:hover{
    .pi-filter-icon {
        color: #343a40;
    }
}
.p-sortable-column:hover, .p-resizable-column:hover ,.p-column-filter-menu-button-open ,.p-column-filter-menu-button-active{
    .pi-filter-icon {
        visibility: visible;
        opacity: 1;
    }

}

/* Hide all sort icons initially */
.p-sortable-column .p-sortable-column-icon {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s, opacity 0.3s ease-in-out;
}

/* Show sort icons on hover */
.p-sortable-column:hover .p-sortable-column-icon {
    visibility: visible;
    opacity: 1;
}

/* Keep sort icons visible when a column is sorted */
.p-sortable-column.p-highlight .p-sortable-column-icon {
    visibility: visible;
    opacity: 1;
    transition: none;
}

.text-truncate-2-lines {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    color: #343a40 !important;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word; 
    white-space: wrap !important;
    font-size: 12px;
  }
  
  .d-flex .page-number {
    flex-shrink: 0; 
    margin-left: 15px;
  }
  
  .d-flex .font-size-16 {
    white-space: nowrap; 
  }

  .min-height-10rem {
    min-height: 10rem !important;
  }
  
  .upload-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.upload-icon {
    transition: transform 0.3s ease;
}

.upload-icon.bounce-animation {
    animation: icon-bounce 0.5s infinite alternate;
}

.text-wrapper {
    text-align: center;
}

.drop-text-container {
    margin-top: 10px;
}

.height-70vh {
    min-height: 70vh;
    height: 100%;
}

.w-50vw {
    width: 50vw;
}

@keyframes icon-bounce {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-5px);
    }
}


.file-preview-container {
    margin: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .file-preview-container iframe {
    width: 100%;
    height: 600px;
    border: none;
  }
  
  .doc-viewer {
    width: 100%;
    height: 600px;
  }
  
  .docx-wrapper  {
    height: 75vh !important;
    width: inherit !important;
    overflow: scroll !important;
    scale: 0,8;
    .docx {
        padding: 22pt !important;
        width: 350pt !important;
        max-height:  auto !important;
        overflow-x: scroll !important;
        min-width: 450pt !important;
        min-height: 600pt !important;
        scale: 0.7;
    }
  }

  .compare-table-wrapper {
    overflow: scroll;
    max-width: 80vw;
    max-height: 70vh;
  }
  .p-button.p-button-sm {
    // padding: 0.30rem 0.95rem !important;
    // height: 2.5rem;
    padding: 0.25rem 0.75rem; /* Reduced padding for a smaller button */
    height: 2rem; /* Adjusted height */
    font-size: 0.875rem; /* Smaller font size for the button text */
    border-radius: 0.3rem;
    width: 2.5rem;
}

// .custom-Button {
//     .p-button.p-button-icon-only {
//         // height: 2rem;
//         width: 2.5rem;

//     }
// }
 
.custom-overlay .p-overlaypanel-content {
    padding: 0 !important;
    background-color: #fff; /* Background color */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Shadow effect */


  }
 
  /* Show full text on hover */
  .overlay-list-item:hover {
    background-color: #888484;
  }

  
  /* Styling for the list inside the overlay */
  .overlay-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  /* Individual list item styles */
  .overlay-list-item {
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    transition: background 0.2s ease-in-out;
    text-align: left;
    border-radius: 4px;
    min-width: 120px;

  }
  
  /* Hover effect for list items */
  .overlay-list-item:hover {
    background-color: #f1f1f1; /* Light gray background */
  }
  
  .overlay-list-item .fa-icon {
    font-size: 1.2rem; 
    margin-right: 10px;
    color: #333; 
  }
  
  .overlay-list-item .fa-trash {
    color: #bf1725 !important; /* Delete icon color */
  }
  
  